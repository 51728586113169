
.grid-result {
  margin-bottom: 1em;
  &__ {
    &link {
      border: 3px solid map_get($theme-colors, medium2);
      &:hover {
        text-decoration: none;
        background-color: map-get($theme-colors, light);
        opacity: 0.8;
      }
    }
    &address-icon {
      font-size: 1.250em;
      &:before {
        margin-right: 4px;
      }
    }
  }
}

.grid-result__no-bottom-margin .grid-result {
  margin-bottom: 1em;

  @media (min-width: map_get($grid-breakpoints, lg)) {
    margin-bottom: 0em;
  }
}

.hide-last .grid-result__last {
  display: none;
}