.header-form {
  &__ {
    &label {
      font-size: 1em;
      padding-bottom: 0em;
      @media (min-width: map_get($grid-breakpoints, md)) {
        font-size: 1.375em;
        padding-bottom: .6em;
      }
    }
  }
  .form-control, .custom-select {
    font-size: 1rem;
  }
  .custom-select {
    height: calc(2.5rem + 2px);
  }
}