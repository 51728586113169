.date-list {
  &__ {
    &item {
      display: block;
    }
    &zebra > div:nth-of-type(even) {
      background-color: map-get($theme-colors, light);
    }
    &overflow-hidden > div:nth-child(n+6) {
      display: none;
    }
    &overflow-hidden div:last-child {
      display: block;
      background-color: map-get($theme-colors, light);
      text-align: center;
    }
  }
}