.two-col-events {
  &__{
    &link {
      padding-right: .5em;
      &:hover {
        text-decoration: none;
        background-color: map_get($theme-colors, light);
      }

    }
    &dividing-right-border  {
      @media (min-width: map-get($grid-breakpoints, lg)) {
        border-right: 2px solid map_get($theme-colors, medium2);
      }
    }
    &heading {

    }
    &item-image {
      width: 100%;
      @media (min-width: map_get($grid-breakpoints, lg)) {
        height: 96px;
      }
    }
    &event-heading {
      font-size: 1.25em;
      line-height: 1.25em;
    }
    &event-dates {
      font-size: 0.938em;
      color: map_get($theme-colors, dark)
    }
    &btn {
      width: 100%;
      text-align: center;
      border-radius: 0;
      font-size: 1.125em;
    }
  }
}

