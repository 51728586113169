//@import "base/_bootstrap-navbar-only.scss";
@import "base/fonts";

.atdwapp {

  @import "base/base";

  @import "utilities/base";

  @import "layout/base";

  @import "modules/base";

}

body, .navbar, .breadcrumbs, .global-footer, .global-footer h2 {
  font-family: "MuseoSans-300", "AvenirLTPro-Light", sans-serif;
  .inside__hidden {
    display: none;
  }
}

@import "print";