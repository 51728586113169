
.list-result {
  &__ {
    &link {
      border: 3px solid map_get($theme-colors, medium2);
      &:hover {
        text-decoration: none;
        background-color: map-get($theme-colors, light);
        opacity: 0.8;
      }
    }
    &img-ctr {
      width: 200px
    }
    &description {
      line-height: 1.1;
    }
    &address-icon {
      font-size: 1.250em;
      &:before {
        margin-right: 4px;
      }
    }
  }
}