.large {
  font-size: 1.5em;
}

.text-weight-black {
  font-weight: bold;
  font-family: "MuseoSans-700", sans-serif;
}

.font-weight-bold {
  font-weight: bold;
  font-family: "MuseoSans-500", sans-serif;
}

.no-hover-underline:hover {
  text-decoration: none;
}

hr {
  height: 2px;
  background-color: lighten(map-get($theme-colors, dark), 65%)
}

.dont-break-out {

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

}