
// Start with assigning color names to specific hex values.
$white:  #fff ;
$black:  #000 ;
$red:    #d9534f ;
$orange: #f0ad4e ;
$yellow: #ffd500 ;
$green:  #5cb85c ;
$blue:   #0275d8 ;
$teal:   #5bc0de ;
$pink:   #ff5b77 ;
$purple: #613d7c ;

$theme-colors: (
  primary: #353535,
  secondary: #f0f0f0,
  light: #f0f0f0,
  medium1: #868686,
  medium2: #E1E1E1,
  dark: #404041,
  darkest-grey: #282828
);
