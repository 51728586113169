.event-gallery {
  &__{
    &link {
      height: 91px;
      display: inline-block;
    }
    &image {
      height: 100%;
    }
  }
}

