
$daterangepicker-ranges-color:               #353535;
$daterangepicker-in-range-bg-color:          #ADB0B3;
$daterangepicker-active-bg-color:            #353535;

@import "../../node_modules/bootstrap-daterangepicker/daterangepicker.scss";

.ranges .range_inputs {
  @extend .d-flex;
  @extend .justify-content-between;
  .btn {
    width: 100%;
    @extend .rounded-0;
    &:first-child {
    }
    &:last-child {
      background-color: transparent;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}