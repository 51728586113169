.hero {
  height: 355px;
  background-size: cover;
  background-repeat: no-repeat;
  &__ {
    &top-text{
      font-size: 1.8em;
      top: 3%;
      padding-left: 15px;
      padding-right: 15px;
    }
    &bottom-main-text {
      font-size: 1.7em;
      bottom: 0%;
      padding: 9px 15px;
      background-color: rgba(0, 0, 0, 0.6);
      p {
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
  }
}