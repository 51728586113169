.form-control {
  border-width: 2px;
  border-color: #BBB;
  @extend .rounded-0;
  background-color: map-get($theme-colors, light);
  &::placeholder {
    color: lighten(map-get($theme-colors, dark), 20%);
  }
}

.bg-dark, .bg-darkest-grey {
  .form-control, .custom-select {
    border-color: $white;
    background-color: transparent;
    @extend .rounded-0;
    border-width: 1px;
    &::placeholder,  .placeholder {
      color: rgba($white, .7)
    }
  }
  .custom-select {
    color: rgba($white, .8);
    option {
      background: map-get($theme-colors, dark);
      color: rgba($white, .8)
    }
  }
}

.header-form {
  &__select {
    background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='70 56 300 381'%3E%3Cpath fill='%23fff' d='m481 231l-212 212c-4 3-8 5-13 5-5 0-9-2-13-5l-212-212c-3-4-5-8-5-13 0-5 2-10 5-13l48-47c3-4 7-6 12-6 5 0 10 2 13 6l152 151 152-151c3-4 8-6 13-6 5 0 9 2 12 6l48 47c3 3 5 8 5 13 0 5-2 9-5 13z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 14px 10px;
    option {
      font-size: 1rem;
    }
  }
}
.form-control__has-right-icon {
  padding-right: 45px;
}
.form-control__has-left-icon {
  padding-left: 50px;
}
.form-control__right-icon {
  right: .55em;
  bottom: .55em;
  font-size: 1.25em
}
.form-control__left-icon {
  left: .4em;
  top: .3em;
  font-size: 1.5rem;
  color: lighten(map-get($theme-colors, dark), 50%);
}

.custom-checkbox {
  .custom-control-label {
    &::before {
      border-radius: 0;
      background-color: transparent;
    }
    &::after {
      @extend .fontastic-icon;
      left: -1.5rem;
      font-size: 1.125em;
      content: "\64";
      background-color: $gray-100;
      color: $gray-800
    }
  }
  .custom-control-input:checked {
    ~ .custom-control-label::before {
      background-color: transparent;
    }
    ~ .custom-control-label::after {
      content: "\65"
    }
  }
}

/* Select overrides */
select.form-control:not([size]):not([multiple]) {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
}
.select-wrapper {
  width: 100%
}