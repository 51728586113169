.list-view-type {
  font-size: 1.25em;
  &__ {
    &link {
      color: lighten($dark, 40%);
      &.active {
        @extend .text-dark;
      }
    }
  }
  :hover {
    text-decoration: none;
  }
}