@import "colors";

$success: map_get($theme-colors, dark);

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}

// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-rounded:            true ;
$enable-shadows:            false ;
$enable-gradients:          false ;
$enable-transitions:        false ;
$enable-hover-media-query:  false ;
$enable-grid-classes:       true ;
$enable-print-styles:       true ;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1050px
);
$grid-gutter-width:           18px !default;

$border-radius:          .4rem ;
$border-radius-lg:       .3rem ;
$border-radius-sm:       .2rem ;

$text-muted: #687178;

//Spacers

$spacer: 1rem;
$spacers: (
  -6: ($spacer * -6),
  -5: ($spacer * -5),
  -4: ($spacer * -4),
  -3: ($spacer * -3),
  -2: ($spacer * -2),
  -1: ($spacer * -1),
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3)
);

$custom-select-indicator-color:     $white;

$custom-checkbox-indicator-icon-checked: "\64";