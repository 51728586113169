@import "bootstrap";
@import "typography";
@import "forms";
@import "buttons";
@import "tables";
@import "col";
@import "headings";

.museo700 {
  font-family: MuseoSans-700, "Arial Black", sans-serif;
}