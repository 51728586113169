@import '../base/colors';
.header-row-item {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  &__ {
    &height {
      height: 190px;
    }
    &date-ctr {
      top: 0;
      left: 0;
      z-index: 1000;
    }
    &vertical-date {
      @extend .d-flex;
      @extend .flex-column
    }
    &day, &month {
      @extend .d-block;
    }
    &month {
      text-transform: uppercase;
      font-size: .9em;
    }
    &day {
      text-align: center;
      font-size: 1.563em;
    }

    &title {
      bottom: 10%;
      background-image: url("../images/header-row-tag.png");
      background-position: top right;
      padding: 0 1em 0 .5em;
      font-family: MuseoSans-700, "Arial Black" ,sans-serif;
      &:before {
        content: '';
        display: inline-block;
        height: 27px;
        vertical-align: middle;
      }
    }
    &hover-to-top {
      top: 100%;
      height: 100%;
      width: 100%;
      color: $white;
      background-color: rgba(map-get($theme-colors, dark), .85);
      transition: top 300ms;
      z-index: 500;
      font-size: 1.125em;
    }

    &blurb-title-font {
      font-size: 120%
    }
  }
  &:hover {
    text-decoration: none;
    .header-row-item__description {
      top: 0%;
    }
  }
}