h1, .h1 {
  font-size: 2.625rem;

}

.h2 {
  font-size: 2rem;
}
@media (min-width: map_get($grid-breakpoints, md)) {
  .h1 {
    &-md {
      font-size: 2.625rem;
    }
  }
}
.page-heading {
  font-size: 1.375rem;
  font-family: "MuseoSans-300";
  border-bottom: 1px solid #d7d7d7;
  @extend .pb-2;
  @media (min-width: 66em) {
    font-size: 2.625rem;
    margin-top: 0.6rem;
    border-bottom-width: 2px;
    font-weight: normal;
  }
}

.feature-heading {
  margin: 0 0 0 0;
  padding-top: .7rem;
}
.small-underlined-title {
  font-family: "MuseoSans-700", sans-serif;
  font-size: 1rem;
  border-bottom: 2px solid lighten(map-get($theme-colors, dark), 60%);
  padding-bottom: .3em;
  margin-bottom: .8em;
}
.sidebar-form {
  h3, .facet-title {
    font-size: 1.125rem;
  }
}